import * as React from 'react';

import Container from '../components/Container';

const NotFoundPage: React.FC = () => {
  return (
    <main>
      <title>Seite nicht gefunden</title>
      <Container>
        <div className="w-full py-16 text-center font-serif text-xl">404</div>
        <div className="w-full text-center">
          <span>
            Wir konnten die Seite nicht finden.
            <br />
            Zurück zur{' '}
            <a href="/" className="underline">
              Homepage
            </a>
            .
          </span>
        </div>
      </Container>
    </main>
  );
};

export default NotFoundPage;
